@import "../../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: 'Helvetica', arial, sans-serif;
$boldFontFamily: 'Helvetica-Bold', arial, sans-serif;
$boldFontWeight: 700;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

// ========
// Colors
// ========

$textColor: #30353D;
$linkColor: #eb0a1e;
$labelColor: #000000;
$formColor: #30353D;
$utilColor: #eb0a1e;
$priceColor: #eb0a1e;
$lightColor: #808285;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #30353D;
$titleSecondaryColor: #eb0a1e;
$subtitleColor: #333333;
$subtitleSecondaryColor: #ffffff;
$successColor: #2DB253;
$warningColor: #f7941d;
$errorColor: #ff3526;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #CB0819;
$colorBtnAlpha: #ffffff;
$bgBtnBeta: #4777B7;
$colorBtnBeta: #ffffff;
$colorBtnOutlineAlpha: #555555;
$colorBtnOutlineBeta: #000000;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 0px;

$borderRadiusForm: 0px;
$borderColorForm: #000000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #16181e;
$calculatorTabsTextColor: #BCBEC0;
$calculatorCloseTabs: #505050;
$calculatorCloseTabsColor: #e3e3e3;
$calculatorMainColor: #ffffff;
$calculatorButton: #CB0819;

$garageBackground: #ffffff;
$garageTextColor: #30353D;
$garageCarNoteColor: #BCBEC0;

$bgTopbarLogin: #30353D;
$colorTopbarLogin: #BCBEC0;

$progressBarTitleBG: #eb0a1e;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #4777B7;
$progressBarCloseButtonBG: #4777B7;
$progressBarCloseButtonColor: #ffffff;
$progressBarSummaryOne: #BCBEC0;
$progressBarSummaryTwo: #939598;
$progressBarSummaryTree: #585858;


$paymentCalculatorClosedBG: #000000;
$paymentCalculatorClosedColor: #e8e8e8;
$paymentCalculatorPanelBG: #30353D;
$paymentCalculatorTermWrapperBG: #373D45;
$paymentCalculatorTermWrapperColor: #BCBEC0;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #30353D;
$paymentCalculatorTermColor: #BCBEC0;

// ========
// Styles
// ========

.widget-sr {
    @include clearfix;
    width: 100%;
    &.dealer__auto-one-international {
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        padding-top: 1px;
        &.sticky-login{
            background-color: $bgTopbarLogin;
        }
        &.Checkout{
            background-color: $bgSecondaryColor;
        }
        &.sr-progressBar {
            .TransactionSummaryUnit--single:first-child {
                background-color: $progressBarSummaryOne;
            }
            .TransactionSummaryUnit--single:nth-child(2) {
                background-color:  $progressBarSummaryTwo;
            }
            .TransactionSummaryUnit--single:nth-child(3) {
                background-color:  $progressBarSummaryTree;
            }
        }
        .btn-register {
            background-color: $utilColor;
        }
        .vdp-used-form .questions-successfully-sent, .vdp-used-form form .visit-step{
            color: $linkColor;
        }

        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@media (max-width: 411px){
    .widget-sr {
        &.dealer__auto-one-international {
            .TransactionSummary--wrapper--title span{
                font-size: 10px;
            }
        }
    }
}

